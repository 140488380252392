'use client';

import {MdKeyboardArrowDown} from 'react-icons/md';
import {FaLocationArrow} from 'react-icons/fa6';
import {GiPlainCircle} from 'react-icons/gi';
import {useLocale, useTranslations} from 'next-intl';
import {useGetUserAddressesQuery} from '@/services';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {commonDisableCachingParameters} from '@/common';
import {useSelector} from 'react-redux';
import {getToken} from '@/selectors';

const AddressesList = ({setSelectedAddress, selectedAddress}) => {
  const t = useTranslations();
  const locale = useLocale();
  const token = useSelector(getToken);
  const [showUserAddresses, setShowUserAddresses] = useState(false);
  const {data: addresses} = useGetUserAddressesQuery(
    {locale},
    {skip: !token, ...commonDisableCachingParameters},
  );

  const defaultAddress = useMemo(
    () =>
      addresses?.data?.find(address => address?.attributes?.default === true),
    [addresses],
  );

  useEffect(() => setSelectedAddress(defaultAddress), [defaultAddress]);

  const handleAddressClick = address => {
    setSelectedAddress(address);
    setShowUserAddresses(false);
  };

  return (
    <div
      className="relative mb-8 flex w-full cursor-pointer items-center justify-end"
      onClick={() => setShowUserAddresses(!showUserAddresses)}>
      <button
        type="button"
        className="font-secondary text-[16px] font-normal text-persianIndigo underline">
        {selectedAddress ? t('use_another_address') : t('use_existing_address')}
      </button>
      <MdKeyboardArrowDown className="text-persianIndigo" size={20} />

      {showUserAddresses && (
        <div
          className={`${locale === 'en' ? 'right-[-1rem]' : 'left-[-1rem]'} absolute top-[2.375rem] z-10 flex flex-col`}>
          <div className="relative">
            <div
              className={`absolute ${locale === 'en' ? 'right-[0.860rem]' : 'left-[0.860rem]'} top-[-0.75rem] z-10 h-0 w-0 border-b-[12px] border-l-[12px] border-r-[12px] border-transparent border-b-persianIndigo`}
            />

            <div
              className={`absolute ${locale === 'en' ? 'right-[0.860rem]' : 'left-[0.860rem]'} top-[-0.658rem] z-20 h-0 w-0 border-b-[12px] border-l-[12px] border-r-[12px] border-transparent border-b-white`}
            />
          </div>
          <div className="z-10 h-[14.125rem] w-[20.25rem] overflow-y-auto rounded-md border-[0.5px] border-persianIndigo bg-white shadow-lg scrollbar-hide">
            {addresses?.data.map((address, index) => (
              <Fragment key={index}>
                <div
                  key={index}
                  className="group flex cursor-pointer items-center justify-between p-[1rem] pt-[2rem] text-gray"
                  onClick={() => handleAddressClick(address)}>
                  <div className="flex w-[70%] items-center justify-start gap-[1rem]">
                    <FaLocationArrow
                      color="#D6BDD0"
                      className={`${address.id === selectedAddress?.id ? 'rotate-180' : 'rotate-0'} transition group-hover:rotate-180`}
                      size={20}
                    />
                    <div className="w-[70%]">
                      {address.attributes.firstname ? (
                        <p className="font-main text-[12px] font-bold text-persianIndigo">
                          {address.attributes.firstname}
                        </p>
                      ) : null}
                      {address.attributes.address1 && (
                        <p className="font-secondary text-[10px] font-normal text-persianIndigo">
                          {address.attributes.address1}
                        </p>
                      )}
                      {address.attributes.national_address && (
                        <p className="font-secondary text-[10px] font-normal text-persianIndigo">
                          {address.attributes.national_address}
                        </p>
                      )}
                    </div>
                  </div>
                  <GiPlainCircle
                    className={`${address.id === selectedAddress?.id ? 'text-thistle' : 'text-white'} rounded-full border border-persianIndigo transition group-hover:bg-thistle group-hover:text-thistle`}
                    size={15}
                  />
                </div>
                {index === addresses.data.length - 1 ? null : (
                  <hr className="mx-auto w-[80%] border-0 border-t border-Isabelline" />
                )}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressesList;
